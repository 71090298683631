import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "../calendar.css";
import { format } from "date-fns"; // Import the date formatting function
import nbLocale from "date-fns/locale/nb"; // Import the Norwegian locale
import axios from "axios";
import { validateField } from "../components/formUtils";

function CalendarComponent({ data, setData, sourcePage }) {
  // State to hold the selected date
  const [selectedDate, setSelectedDate] = useState(null);

  // State to hold the wanda time slots
  const [wandaTimeSlots, setWandaTimeSlots] = useState({});

  // State to hold the selected month for the wanda time slots
  const [currentMonth, setCurrentMonth] = useState(new Date());

  // State to track loading status
  const [isLoading, setIsLoading] = useState(false);

  // New state to track the month of the loaded timeslots
  const [loadedMonth, setLoadedMonth] = useState(null);

  //State to hold store the availability data of a given month from the dailymovementsummary model
  const [availability, setAvailability] = useState({});

  // State to store pre-calculated booked minutes per day
  const [bookedMinutesPerDay, setBookedMinutesPerDay] = useState({});

  // Fetch the bookings from the database when the component mounts
  // Fetch bookings only for ServiceBookingPage
  // Fetch the bookings from the database when the component mounts
  useEffect(() => {
    if (sourcePage === "ServiceBookingPage") {
      axios
        .get("/api/bookings")
        .then((response) => {
          // Pre-calculate booked minutes per day
          const minutesPerDay = {};
          response.data.forEach((booking) => {
            const formattedDate = format(
              new Date(booking.bookingDate),
              "yyyy-MM-dd"
            );

            if (!minutesPerDay[formattedDate]) {
              minutesPerDay[formattedDate] = 0;
            }

            // Add minutes based on booking type
            if (booking.type === "Service") {
              minutesPerDay[formattedDate] += SERVICE_MINUTES;
            } else if (booking.type === "Reparasjon") {
              minutesPerDay[formattedDate] += REPARASJON_MINUTES;
            } else if (booking.type === "Dekkskifte") {
              minutesPerDay[formattedDate] += DEKKSKIFTE_MINUTES;
            }
          });

          // Store the calculated minutes per day
          setBookedMinutesPerDay(minutesPerDay);
        })
        .catch((error) => {
          console.error("There was an error fetching bookings:", error);
        });
    }
  }, [sourcePage]);

  //Fetches data from dailymovements to see if we have set the availability of a given date to false
  useEffect(() => {
    const fetchAvailability = async () => {
      const month = currentMonth.getMonth() + 1; // JavaScript months are 0-indexed
      const year = currentMonth.getFullYear();

      try {
        const response = await axios.get("/api/dailyMovements/availability", {
          params: { month, year },
        });
        setAvailability(response.data);
      } catch (error) {
        console.error("Error fetching availability data:", error);
        setAvailability({});
      }
    };

    fetchAvailability();
  }, [currentMonth]);

  // useEffect to reset loadedMonth when postalCode changes
  useEffect(() => {
    if (data.postalCode) {
      setLoadedMonth(null);
    }
  }, [data.postalCode]);

  // useEffect for fetching Wanda timeslots
  useEffect(() => {
    const fetchWandaTimeslots = async () => {
      if (data.deliveryType === "wanda" && data.postalCode && data.wandaType) {
        const month = (currentMonth.getMonth() + 1).toString();
        const year = currentMonth.getFullYear().toString();
        const monthYear = `${year}-${month}`;

        // Check if the timeslots for the current month are already loaded
        if (loadedMonth !== monthYear) {
          setIsLoading(true); // Start loading
          try {
            const requestBody = {
              postalCode: data.postalCode,
              orderType: data.wandaType,
              year,
              month,
            };

            const response = await axios.post(
              "/api/wanda-timeslots",
              requestBody
            );

            // Filter out the "0900-2200" timeslots and unavailable timeslots
            const filteredTimeSlots = Object.entries(response.data).reduce(
              (acc, [date, slots]) => {
                // Filter out timeslots where "isOpen" is false and the key is not "0900-2200"
                acc[date] = slots.filter(
                  (slot) => slot.isOpen && slot.key !== "0900-2200"
                );
                return acc;
              },
              {}
            );

            setWandaTimeSlots(filteredTimeSlots);
            setLoadedMonth(monthYear); // Update the loaded month
          } catch (error) {
            console.error("Error fetching timeslots:", error);
            setWandaTimeSlots({});
          } finally {
            setIsLoading(false); // Stop loading
          }
        }
      }
    };

    fetchWandaTimeslots();
  }, [currentMonth, data, loadedMonth]);

  // Function to count the booked capacity for a given date

  // Maximum capacity per day in minutes (15 hours = 900 minutes)
  const MAX_MINUTES_PER_DAY = 900;

  // Estimated time per booking type in minutes
  const SERVICE_MINUTES = 60;
  const REPARASJON_MINUTES = 60;
  const DEKKSKIFTE_MINUTES = 20;

  // Function to count the total booked minutes for a given date, only reads from the pre-calculated map
  const calculateBookedMinutes = (date) => {
    if (sourcePage !== "ServiceBookingPage") {
      return 0;
    }

    const formattedDate = format(date, "yyyy-MM-dd");
    const totalMinutes = bookedMinutesPerDay[formattedDate] || 0;

    return totalMinutes;
  };

  // Function to check if a date falls on the weekend
  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  // Function to determine if a date should be selectable based on several criteria
  const isSelectable = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Resets the time component of today

    let minSelectableDate = new Date();
    minSelectableDate.setHours(0, 0, 0, 0); // Resets the time component of minSelectableDate

    const dateString = format(date, "yyyy-MM-dd");

    // Checking availability from dailymovement summary data
    if (
      (sourcePage === "DeliveryPage" || sourcePage === "AvailabilityPage") &&
      availability.hasOwnProperty(dateString) &&
      !availability[dateString]
    ) {
      return false;
    }

    if (sourcePage === "DeliveryPage" || sourcePage === "AvailabilityPage") {
      let daysToAdd = 0;
      let daysCounted = 0;

      // Add days until three working days are counted
      while (daysCounted < 5) {
        daysToAdd++;
        const nextDay = new Date(today);
        nextDay.setDate(today.getDate() + daysToAdd);

        // Count only weekdays
        if (nextDay.getDay() !== 0 && nextDay.getDay() !== 6) {
          daysCounted++;
        }
      }

      minSelectableDate.setDate(today.getDate() + daysToAdd);
    } else {
      minSelectableDate.setDate(today.getDate() + 1); // For other pages, it's the next day
    }

    // Block dates before minSelectableDate
    if (date < minSelectableDate) {
      return false;
    }
    // Block past dates including today for both ServiceBookingPage and AvailabilityPage
    if (date < minSelectableDate) {
      return false;
    }

    // Block weekends for nydalen delivery type
    if (isWeekend(date) && data.deliveryType === "nydalen") {
      return false;
    }

    // Block weekends for all services
    if (sourcePage === "ServiceBookingPage" && isWeekend(date)) {
      return false;
    }

    // Calculate total booked minutes for the given date
    if (sourcePage === "ServiceBookingPage") {
      const bookedMinutes = calculateBookedMinutes(date);

      // If the total booked minutes exceed or equal the maximum capacity, make the date unselectable
      if (bookedMinutes >= MAX_MINUTES_PER_DAY) {
        return false;
      }
    }

    // Conditions for 'wanda' deliveryType
    if (data.deliveryType === "wanda") {
      const dateString = format(date, "yyyy-MM-dd");
      const dayTimeslots = wandaTimeSlots[dateString];

      // A date is not selectable if all timeslots are unavailable or if there are no timeslots
      if (!dayTimeslots || dayTimeslots.every((slot) => !slot.isOpen)) {
        return false;
      }
    }

    return true;
  };

  // Function to handle the date change
  const handleDateChange = (date) => {
    if (isSelectable(date)) {
      setSelectedDate(date);
      const formattedDate = format(date, "yyyy-MM-dd");

      // Check if there's only one available timeslot for the selected date
      const availableTimeSlots = wandaTimeSlots[formattedDate] || [];
      if (availableTimeSlots.length === 1 && availableTimeSlots[0].isOpen) {
        // Auto-select the timeslot
        setData({
          ...data,
          selectedTimeslot: availableTimeSlots[0],
          [sourcePage === "DeliveryPage" || sourcePage === "ReturnPage"
            ? "deliveryDate"
            : sourcePage === "ServiceBookingPage"
            ? "bookingDate"
            : "returnDate"]: formattedDate,
        });
      } else {
        // Reset selectedTimeslot when a new date is selected
        let newState = {};
        if (sourcePage === "DeliveryPage" || sourcePage === "ReturnPage") {
          newState = { deliveryDate: formattedDate, selectedTimeslot: "" };
        } else if (sourcePage === "ServiceBookingPage") {
          newState = { bookingDate: formattedDate, selectedTimeslot: "" };
        } else if (sourcePage === "ServiceReturnPage") {
          newState = { returnDate: formattedDate, selectedTimeslot: "" };
        }

        setData({ ...data, ...newState });
      }
    }
  };

  // Function to handle the month change for the wanda timeslots
  const handleMonthChange = (date) => {
    setCurrentMonth(date);
    setSelectedDate(null); // Reset the selectedDate when a new month is selected

    // Reset the selected date and timeslot when a new month is selected
    let newState = { selectedTimeslot: "" };
    if (sourcePage === "DeliveryPage" || sourcePage === "ReturnPage") {
      newState = { ...newState, deliveryDate: "" };
    } else if (sourcePage === "ServiceBookingPage") {
      newState = { ...newState, bookingDate: "" };
    } else if (sourcePage === "ServiceReturnPage") {
      newState = { ...newState, returnDate: "" };
    }

    setData({ ...data, ...newState });
  };

  // Function to set the selected timeslot
  const handleTimeslotSelect = (timeslot) => {
    setData({ ...data, selectedTimeslot: timeslot });
  };

  // Function to set the class of the calendar tile
  const customTileClassName = ({ date, view }) => {
    if (view === "month") {
      return isSelectable(date) ? "" : "not-selectable";
    }
    return "";
  };

  return (
    <div className="my-2 text-center relative">
      <div className="bg-white p-6 rounded-lg shadow-md mx-auto w-full sm:w-2/3 md:w-1/2 flex justify-center">
        <Calendar
          onChange={handleDateChange}
          value={selectedDate}
          selectRange={false}
          tileDisabled={({ date }) => !isSelectable(date)}
          minDetail="month"
          tileClassName={customTileClassName}
          next2Label={null}
          prev2Label={null}
          locale={nbLocale}
          onActiveStartDateChange={({ activeStartDate }) =>
            handleMonthChange(activeStartDate)
          }
        />
      </div>
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50 flex justify-center items-center rounded-lg">
          <p className="text-white text-lg">Loading...</p>
        </div>
      )}
      {validateField("selectedDate", selectedDate) && (
        <p className="text-gray-400 mt-4">Dato er nødvendig.</p>
      )}
      {selectedDate && (
        <p className="mt-3 text-white text-sm">
          Valgt dato: {format(selectedDate, "PP", { locale: nbLocale })}
        </p>
      )}
      {selectedDate && data.deliveryType === "wanda" && (
        <div className="my-6">
          <h2 className="mt-6 mb-4 text-center text-md font-century-gothic text-jouletext">
            Ledige tidspunkt
          </h2>
          <div className="flex flex-wrap justify-center gap-3">
            {wandaTimeSlots[format(selectedDate, "yyyy-MM-dd")]?.map((slot) => {
              const isSelected =
                data.selectedTimeslot && data.selectedTimeslot.key === slot.key;

              return (
                <button
                  key={slot.key}
                  onClick={() => handleTimeslotSelect(slot)}
                  className={`px-4 py-2 text-sm rounded-lg transition-colors duration-200 ease-in-out inline-flex items-center ${
                    isSelected
                      ? "bg-white text-black font-bold shadow-black shadow-lg focus:transform focus:scale-105"
                      : "bg-jouledark text-white hover:bg-gray-100 hover:text-black"
                  }`}
                >
                  <span>
                    {slot.from} - {slot.to}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default CalendarComponent;
